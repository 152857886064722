<template>
  <div>

    <validation-observer

      ref="form"
    >

      <empty-card
        :title="$t('notifications.telegram')"
      >
        <template slot="title">
          <div
            class="d-flex align-items-center"
          >
            <b-button
              variant="dark"
              :disabled="submitting || fetching"
              @click="submit()"
            >
              <span :class="{'pr-1': submitting}">{{ $t('forms.save') }}</span>
              <b-spinner
                v-if="submitting"
                small
              />
            </b-button>
          </div>
        </template>
        <fetching-data-spinner
          :loading="fetching"
          :loading-title="$t('general.loading-data')"
          enable-retry
        />
        <div
          v-if="!fetching"
          class="mt-2"
        >
          <error-alert
            v-if="error"
            :error="error"
          />
          <b-row class="align-items-center">
            <b-col
              md="12"
              class="mb-2"
            >
              <b-form-checkbox
                v-model="form.enabled"
                switch
              >
                {{ $t(`notifications.telegram-enable`) }}
              </b-form-checkbox>
            </b-col>
            <template v-if="form.enabled">
              <b-col
                md="6"
                class="mb-1"
              >
                <ok-text-input
                  v-model="form.token"
                  size="md"
                  :label="$t('notifications.telegram-token')"
                  is-required
                >
                  <template #footer>
                    <p
                      class="cursor-pointer text-underline text-primary"
                      style="margin-top: 5px;text-decoration: underline;font-size: 12px;margin-bottom: 0"
                      @click="openModal"
                    >
                      {{ $t('notifications.telegram-guide') }}
                    </p>
                  </template>
                </ok-text-input>

              </b-col>
              <b-col
                v-if="form.status"
                md="12"
              >
                <b-button
                  :disabled="sending"
                  variant="success"
                  @click="test"
                >
                  <b-spinner
                    v-if="sending"
                    small
                    class="mr-50"
                  />
                  {{ $t('notifications.send-telegram') }}
                </b-button>
              </b-col>
              <b-col
                v-else
                md="12"
              >
                <b-button
                  :disabled="sending"
                  variant="primary"
                  @click="check"
                >
                  <b-spinner
                    v-if="sending"
                    small
                    class="mr-50"
                  />
                  {{ $t('notifications.enable-bot') }}
                </b-button>
              </b-col>
            </template>
          </b-row>
        </div>
      </empty-card>
    </validation-observer>
    <help-post-modal
      v-model="showPostModal"
      :question="selectedPost"
    />
  </div>
</template>

<script>
import {
  BButton, BSpinner, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import ErrorHandler from '@/utils/error-handler'
import EmptyCard from '@/@bya3/components/cards/EmptyCard'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import pick from 'lodash/pick'
import { ValidationObserver } from 'vee-validate'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import NotificationsApi from '@/api/notifications'
import HelpPostModal from '@/views/help/components/PostModal'
import HelpAPI from '@/api/admin/help-api'
import FetchingDataSpinner from '@/@bya3/components/utils/FetchingDataSpinner'

export default {
  name: 'TelegramNotification',
  components: {
    FetchingDataSpinner,
    HelpPostModal,
    ErrorAlert,
    EmptyCard,
    BButton,
    OkTextInput,
    ValidationObserver,
    BRow,
    BFormCheckbox,
    BCol,
    BSpinner,
  },
  data() {
    return {
      submitting: false,
      sending: false,
      form: {
        enabled: 0,
        token: '',
      },
      error: null,
      showPostModal: false,
      selectedPost: {},
      post: null,
      fetching: true,
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      await this.$store.dispatch('notifications/FETCH_SETTINGS').then(() => {
        this.form = this.$store.getters['notifications/telegram']
        this.form.enabled = !!this.form.enabled
        this.fetching = false
      })
    },
    async test() {
      this.sending = true
      this.error = null
      NotificationsApi.testTelegram()
        .then(response => {
          if (response.error === 0) {
            this.$notifySuccess(this.$t('notifications.telegram-send-success'))
          } else this.error = response.message
        })
        .catch(error => { this.error = error })
        .finally(() => { this.sending = false })
    },
    async openModal() {
      await this.fetchHelpPost()
      this.showPostModal = true
      this.selectedPost = this.post
    },
    async fetchHelpPost() {
      if (this.post?.id) return
      await HelpAPI.getQuestion('telegram').then(post => {
        this.post = post
      })
    },
    async check() {
      this.sending = true
      this.error = null
      let form = {}
      if (this.$store.getters['notifications/telegram']?.token) {
        form = { ...this.form }
      }
      NotificationsApi.checkTelegram(form)
        .then(response => {
          if (response.error === 0) {
            if (!response.chatId) {
              this.error = this.$t('notifications.telegram-no-chat')
            } else {
              this.form.status = 1
              this.$notifySuccess(this.$t('notifications.telegram-enabled-success'))
              this.$store.commit('notifications/UPDATE_TELEGRAM', this.form)
            }
          } else this.error = response.message
        })
        .catch(error => { this.error = error })
        .finally(() => { this.sending = false })
    },
    async submit() {
      this.error = null
      const valid = await (this.$refs.form.validateWithInfo())
      if (!valid.isValid) {
        this.error = { title: this.$tc('errors.field-invalid', 2), message: ErrorHandler.getValidationErrors(valid.errors) }
        return
      }
      const keys = ['enabled', 'token']
      const payload = pick(this.form, keys)
      this.submitting = true
      NotificationsApi.updateTelegram(payload)
        .then(response => {
          this.$notifySuccess(response.message)
          this.$store.commit('notifications/UPDATE_TELEGRAM', { ...payload })
        })
        .catch(error => { this.error = error })
        .finally(() => { this.submitting = false })
    },
  },
}
</script>
